<template>
  <div class="login-view mt-5">
    <div class="login-panel">

      <div class="login-panel-logo">
      </div>

      <div class="login-panel-text" style="visibility:hidden">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.
      </div>
      <form method="POST" :action="signInUrl">
        <input type="hidden" name="ReturnUrl" v-model="loginForm.returnUrl" />

        <div class="login-panel-form">
          <div class="field">
            <label class="label">E-mail</label>
            <div class="control has-icons-left">
              <input class="input" type="email" name="email" v-model="loginForm.email" />
              <span class="icon is-small is-left">
                <font-awesome-icon icon="envelope"></font-awesome-icon>
              </span>
            </div>
          </div>

          <div class="field">
            <label class="label">Adgangskode</label>
            <div class="control has-icons-left">
              <input class="input" type="password" name="password" v-model="loginForm.password" />
              <span class="icon is-small is-left">
                <font-awesome-icon icon="lock"></font-awesome-icon>
              </span>
            </div>
          </div>
        </div>

        <div class="login-panel-controls">
          <div class="login-error-panel" v-if="loginError">
            <span>Forkert E-mail eller Adgangskode</span>
          </div>
          <button type="submit" class="button is-fullwidth is-primary">Log ind</button>
        </div>

        <div class="login-panel-links">
          <router-link :to="{ name: 'ResetPassword' }">Nulstil kodeord</router-link>
        </div>
      </form>

    </div>
  </div>
</template>

<script>
import { oidcSettings } from '../../config/oidc';
import Cookies from 'js-cookie';

const authority = oidcSettings.authority;

export default {
  name: 'login-view',
  data: () => ({
    loginForm: {
      email: '',
      password: '',
      returnUrl: (new URLSearchParams(window.location.search)).get('ReturnUrl'),
      rememberMe: false
    },
    loginError: false
  }),
  mounted() {
    if (Cookies.get('loginerr') === "true") {
      Cookies.remove('loginerr');
      this.loginError = true;
    }
  },
  computed: {
    signInUrl() {
      return `${authority}/api/authorization/login`;
    }
  }
}
</script>

<style lang="scss">
.login-view {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;

  .login-panel {
    flex: 1;
    display: flex;
    flex-flow: column;
    max-width: 400px;
    gap: 15px;

    .login-panel-controls {
      margin-top: 10px;

      .login-error-panel {
        padding: 5px 5px 15px 5px;
        border-radius: 4px;
        border-bottom: none;
        background-color: $danger;
        color: white;
        position: relative;
        top: 10px;
        width: calc(100% - 30px);
        margin: auto;
        font-size: smaller;
        font-weight: 700;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
      }
    }
    .login-panel-logo {
      background-image: url(../../assets/vucstorstroemlogo.svg);
      background-repeat: no-repeat;
      background-position: center;
      min-height: 150px;
    }
    .login-panel-links {
      display: flex;
      justify-content: center;
      padding-top: 10px;
      a {
        color: black;
        text-decoration: underline;
        font-size: x-small;
      }
    }
  }
}
</style>
